import styled from "styled-components"

import { AxiosError } from "axios"
import { isFuture, parseISO } from "date-fns"

import { HREF_MINUT_MRS_BILLING_REGIONS } from "src/constants/hrefs"
import {
  AddonType,
  IEstimateAddon,
  IEstimateSubscription,
  IResponseServiceErrorResponse,
  IResponseServiceToggleEstimateResponse,
} from "src/data/homes/types/responseServiceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { divider } from "src/ui/colors"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"
import { formatDate } from "src/utils/l10n"

export function EnableStep({
  missingPhoneNumberError,
  estimateData,
  estimateError,
  error,
  responseServiceAvailable,
}: {
  missingPhoneNumberError: boolean
  estimateData: IResponseServiceToggleEstimateResponse | undefined
  estimateError: AxiosError<IResponseServiceErrorResponse> | null
  error?: boolean
  responseServiceAvailable?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const cost = getCost(estimateData)

  if (!!estimateError) {
    return (
      <MBanner fullWidth type="error">
        {estimateError.response?.data.error_key === "unsupported_currency"
          ? t(langKeys.response_service_failed_unsupported_currency)
          : t(langKeys.failed_something_went_wrong)}
      </MBanner>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.response_service_wizard_enable_title)}
      </MText>

      <GridRow>
        <MText variant="subtitle">
          {t(langKeys.response_service_wizard_enable_extra_home)}
        </MText>
        <RecurringCost cost={cost} />
      </GridRow>

      <GridRow>
        <div>
          <MText variant="subtitle">
            {t(langKeys.response_service_wizard_enable_responder_title)}
          </MText>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.response_service_wizard_enable_callout_text)}
          </MText>
        </div>
        <CalloutCost cost={cost} />
      </GridRow>

      <NotesBox>
        <MText variant="bodyS" color="secondary">
          {t(langKeys.response_service_wizard_enable_asterisk)}{" "}
          <ExternalLink href={HREF_MINUT_MRS_BILLING_REGIONS}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </MText>

        {error && (
          <MBanner type="error" fullWidth>
            {t(langKeys.response_service_wizard_enable_wrong_location)}
          </MBanner>
        )}
        {responseServiceAvailable === false && (
          <MBanner type="error" fullWidth>
            {t(langKeys.response_service_wizard_enable_wrong_location)}
          </MBanner>
        )}

        {missingPhoneNumberError && (
          <MBanner type="error" fullWidth>
            <MissingPhoneNumberBox>
              <MText variant="subtitle" color="unset">
                {t(langKeys.response_service_missing_phone_number_title)}
              </MText>
              <MText variant="subtitle" color="unset">
                {t(langKeys.response_service_missing_phone_number_text)}
              </MText>
            </MissingPhoneNumberBox>
          </MBanner>
        )}
      </NotesBox>
    </div>
  )
}

function RecurringCost({ cost }: { cost: IResponseServiceCost | null }) {
  const { t, langKeys } = useTranslate()
  if (!cost) {
    return (
      <MText variant="body" textAlign="right">
        <MSkeleton />
      </MText>
    )
  }

  if (cost.trial_end && isFuture(parseISO(cost.trial_end))) {
    return (
      <MText variant="body" textAlign="right">
        <div>
          {t(langKeys.response_service_wizard_enable_free_trial, {
            date: formatDate({ date: cost.trial_end, excludeTime: true }),
          })}
        </div>
        <div>
          {t(langKeys.response_service_wizard_enable_when_trial_ends, {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            price: `${formatPrice(cost.unit_cost / 100, cost.currency!)} / ${
              cost.billing_period
            }`,
          })}
        </div>
      </MText>
    )
  }

  return (
    <MText variant="body" textAlign="right">
      <div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {formatPrice(cost.unit_cost / 100, cost.currency!)} /{" "}
        {cost.billing_period}
      </div>
    </MText>
  )
}

function CalloutCost({ cost }: { cost: IResponseServiceCost | null }) {
  const { t, langKeys } = useTranslate()

  if (!cost) {
    return (
      <MText variant="body" textAlign="right">
        <MSkeleton variant="text" width={150} />
      </MText>
    )
  }

  return (
    <MText variant="body" textAlign="right">
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      {formatPrice(cost.dispatch_cost / 100, cost.currency!)} /{" "}
      {t(langKeys.response_service_wizard_enable_callout)}
    </MText>
  )
}

interface IResponseServiceCost extends IEstimateAddon, IEstimateSubscription {}

function getCost(
  estimateResponse?: IResponseServiceToggleEstimateResponse
): IResponseServiceCost | null {
  if (!estimateResponse) {
    return null
  }

  const addons = estimateResponse.addons.filter(
    (addon) => addon.type === AddonType.RESPONSE_SERVICE
  )

  if (!addons || addons.length > 1) {
    throw Error(
      `Expected one response service addon got multiple ${JSON.stringify(
        estimateResponse
      )}`
    )
  }

  return { ...estimateResponse.subscription, ...addons[0] }
}

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${spacing.M};
  margin-bottom: ${spacing.M};
`

const NotesBox = styled.div`
  padding-top: ${spacing.L};
  margin-top: ${spacing.L};
  border-top: 1px solid ${divider};
  display: grid;
  grid-gap: ${spacing.L};
`

const MissingPhoneNumberBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`
